export const nav = [
  {
    text: "əsas",
    path: "/",
  },
  {
    text: "haqqımızda",
    path: "/about",
  },
  {
    text: "tərəfdaşlarımız",
    path: "/partners",
  },
  // {
  //   text: "Avadanlıqlar",
  //   path: "/avadanliqlar",
  // },
  // {
  //   text: "məhsullar",
  //   path: "/pricing",
  // },
  {
    text: "əlaqə",
    path: "/contact",
  },
  {
    text: "kampaniyalar",
    path: "/kampaniyalar",
  }

]
export const featured = [
  {
    cover: "../images/hero/h1.png",
    name: "Ticarət",
    total: "12 fərqli sektor",
  },
  {
    cover: "../images/hero/h2.png",
    name: "İdarəetmə",
    total: "15 fərqli sektor",
  },
  {
    cover: "../images/hero/h3.png",
    name: "Xidmət",
    total: "30 fərqli sektor",
  },
  {
    cover: "../images/hero/h4.png",
    name: "Biznes",
    total: "17 fərqli sektor",
  },
  {
    cover: "../images/hero/h6.png",
    name: "İstehsalat",
    total: "21 fərqli sektor",
  },
]
export const list = [
  {
    id: 1,
    cover: "../images/list/BarkodOxuyucu.png",
    name: "Barkod oxuyucular",
    location: "Bakı, Azərbaycan", 
    category: "Satış",
    price: "$3,700",
    type: "Apartment",
    path: 'barkod-oxuyucu'
  },
  {
    id: 2,
    cover: "../images/list/BarkodPrinter.png",
    name: "Barkod printerlər",
    location: "Bakı, Azərbaycan",
    category: "Satış",
    price: "$9,750",
    type: "Condos",
    path: 'barkod-printer'
  },
  {
    id: 3,
    cover: "../images/list/BarkodTerezi.png",
    name: "Barkod tərəzilər",
    location: "Bakı, Azərbaycan",
    category: "Satış",
    price: "$5,860",
    type: "Offices",
     path: 'barkod-terezi'
  },
  {
    id: 4,
    cover: "../images/list/PulQutulari.png",
    name: "Pul qutuları",
    location: "Bakı, Azərbaycan",
    category: "Satış",
    price: "$7,540",
    type: "Homes & Villas",
      path: 'pul-qutulari'
  },
  {
    id: 5,
    cover: "../images/list/CekPrinter.png",
    name: "Çek printerlər",
    location: "Bakı, Azərbaycan",
    category: "Satış",
    price: "$4,850",
    type: "Commercial",
       path: 'cek-printer'
  },
  {
    id: 6,
    cover: "../images/list/Komputerler.png",
    name: "Toxunuşlu Monitorlar",
    location: "Bakı, Azərbaycan",
    category: "Satış",
    price: "$2,742",
    type: "Apartment",
       path: 'touchscreen'
  },
  {
    id: 7,
    cover: "../images/list/CardReader.png",
    name: "Kart oxuyucular",
    location: "Bakı, Azərbaycan",
    category: "Satış",
    price: "$2,742",
    type: "Apartment",
       path: 'cardreader'
  },
  {
    id: 8,
    cover: "../images/list/Komputerler.png",
    name: "Fiyatgörlər",
    location: "Bakı, Azərbaycan",
    category: "Satış",
    price: "$2,742",
    type: "Apartment",
     path: 'fiyatgor'
  },
  {
    id: 9,
    cover: "../images/list/Kagizlar.png",
    name: "Kağızlar",
    location: "Bakı, Azərbaycan",
    category: "Satış",
    price: "$2,742",
    type: "Apartment",
     path: 'kagizlar'
  },
  {
    id: 10,
    cover: "../images/list/Komputerler.png",
    name: "Komputerlər",
    location: "Bakı, Azərbaycan",
    category: "Satış",
    price: "$2,742",
    type: "Apartment",
     path: 'komputerler'
  },
]
export const awards = [
  {
    icon: <i class='fa-solid fa-trophy'></i>,
    num: "3200	",
    name: "Müştərilər",
  },
  {
    icon: <i class='fa-solid fa-briefcase'></i>,
    num: "156",
    name: "Tərəfdaşlar",
  },
  {
    icon: <i class='fa-solid fa-lightbulb'></i>,
    num: "5100",
    name: "İstifadəçilər",
  },
  {
    icon: <i class='fa-solid fa-heart'></i>,
    num: "44",
    name: "İş ortaqları",
  },
]
export const location = [
  {
    id: 1,
    name: "Hər yerdən giriş imkanı",
    Villas: "12 lorem",
    Apartments: "10 lorem",
    Offices: "07 lorem",
    cover: "./images/location/about.webp",
  },
  {
    id: 2,
    name: "Yalnız sizə lazım olan funksiyalar",
    Villas: "12 lorem",
    Apartments: "10 lorem",
    Offices: "07 lorem",
    cover: "./images/location/about.webp",
  },
  {
    id: 3,
    name: "Texniki dəstək",
    Villas: "12 lorem",
    Apartments: " 10 lorem",
    Offices: "07 lorem",
    cover: "./images/location/about.webp",
  },
  {
    id: 4,
    name: "Geniş çeşidli xidmətlər",
    Villas: "12 lorem",
    Apartments: " 10 lorem",
    Offices: "07 lorem",
    cover: "./images/location/about.webp",
  },
  {
    id: 5,
    name: "Avandanlıqların quraşdırlması",
    Villas: "12 lorem",
    Apartments: " 10 lorem",
    Offices: "07 lorem",
    cover: "./images/location/about.webp",
  },
  {
    id: 6,
    name: "Analitik sistemlər",
    Villas: "12 lorem",
    Apartments: " 10 lorem",
    Offices: "07 lorem",
    cover: "./images/location/about.webp",
  },
]
export const team = [
  {
    list: "50",
    cover: "../images/customer/team-1.jpg",
    address: "Bakı, Azərbaycan",
    name: "Lorem ipsum",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "70",
    cover: "../images/customer/team-2.jpg",
    address: "Bakı, Azərbaycan",
    name: "Lorem ipsum",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "80",
    cover: "../images/customer/team-3.jpg",
    address: "Bakı, Azərbaycan",
    name: "Lorem ipsum",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "51",
    cover: "../images/customer/team-4.jpg",
    address: "Bakı, Azərbaycan",
    name: "Lorem ipsum",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "42",
    cover: "../images/customer/team-5.jpg",
    address: "Bakı, Azərbaycan",
    name: "Lorem ipsum",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "38",
    cover: "../images/customer/team-5.jpg",
    address: "Bakı, Azərbaycan",
    name: "Lorem ipsum",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
]
export const price = [
  {
    plan: "Market",
    price: "29",
    ptext: "per user, per month",
    image: './55.jpg',
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "120GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "5GB Cloud Storage",
      },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Personal Help Support" },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Enterprise SLA" },
    ],
  },
  {
    best: "Best Value",
    plan: "Restoran",
    price: "49",
    ptext: "per user, per month",
    image: './66.jpg',
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "150GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "10GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        change: "color",
        icon: <i class='fa-solid fa-x'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: './77.jpg',
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Market",
    price: "29",
    ptext: "per user, per month",
    image: './88.jpg',
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "120GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "5GB Cloud Storage",
      },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Personal Help Support" },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Enterprise SLA" },
    ],
  },
  {
    best: "Best Value",
    plan: "Restoran",
    price: "49",
    ptext: "per user, per month",
    image: './99.jpg',
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "150GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "10GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        change: "color",
        icon: <i class='fa-solid fa-x'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: './100.jpg',
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: './111.jpg',
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: './12.jpg',
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: './13.jpg',
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: './14.jpg',
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: './15.jpg',
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: './16.jpg',
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: './17.jpg',
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: './18.jpg',
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: './19.jpg',
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  
]
export const footer = [
  {
    title: "Sürətli keçidlər",
    text: [{ list: "Ana səhifə" }, { list: "Haqqımızda" }, { list: "Tərəfdaşlarımız" }, { list: "Avadanlıqlar" }, { list: "Əlaqə" }, { list: "Sektorlar" }],
  },
  {
    title: "Xidmərlərimiz",
    text: [{ list: "Market" }, { list: "Restoran" }, { list: "Şirniyyat istehsalı" }, { list: "KofeShop" }, { list: "Restoran" }, { list: "İnventarın izlənilməsi" }],
  },
  {
    title: "Xidmətlərimiz",
    text: [{ list: "Kafe" }, { list: "Geyim mağazaları" }, { list: "İstehsalat" }, { list: "Oyuncaq mağazaları" }, { list: "Avto Ehtiyyat Hissələri" }, { list: "Xirdavat" }],
  },
]
