import React from "react";
import { ImCross } from "react-icons/im";
import { useDispatch } from "react-redux";
import {
  deleteItem,
  drecreaseQuantity,
  increaseQuantity, 
} from "../redux/orebiSlice";
import './ItemCard.css'; // Özel CSS dosyanız

const ItemCard = ({ item }) => {
  const dispatch = useDispatch(); 

  return (
    <div className="item-card-container">
      <div className="item-card">
      {item.image.startsWith("uploads") ? (
        <img className="item-image" src={`https://www.posstore.az/posstore/${item.image}`} alt='' /> 
      ) : (
        <img className="item-image" alt="productImage" src={`data:image/jpeg;base64,${item.image}`} />
      )}
        <div className="item-info">
          <p>{item.name}</p>
        </div>
        <div className="item-actions">
          <div className="quantity-controls">
            <span
              onClick={() => dispatch(drecreaseQuantity({ id: item.id }))}
              className="quantity-button"
            >
              -
            </span>
            <p>{item.quantity}</p>
            <span
              onClick={() => dispatch(increaseQuantity({ id: item.id }))}
              className="quantity-button"
            >
              +
            </span>
          </div>
          <ImCross
            onClick={() => dispatch(deleteItem(item.id))}
            className="delete-icon"
          />
        </div>
        <div className="item-total">
  {item.discountPrice > 0 ? (
    <p style={{color:'green'}}>
      <span style={{color:"red"}}>
      <del>
      <span className="line-through text-gray-500 mr-2">
        {item.quantity * item.price}₼
      </span>
      </del>
      </span>
      <span className="text-red-500">
         {item.quantity * item.discountPrice}₼
      </span>
    </p>
  ) : (
    <p>{item.quantity * item.price}₼</p>
  )}
</div>


      </div>
    </div>
  );
};

export default ItemCard;
