import React, { useEffect, useRef, useState } from "react";
import "./header.css";
import { nav } from "../../data/Data";
import { Link, useNavigate } from "react-router-dom";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { LuMessageSquare } from "react-icons/lu";
import { IoCall } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";


const DashboardHeader = () => { 

  const user = localStorage.getItem("user");
  const naviget = useNavigate();

  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    naviget("/");
  }

  const [navList, setNavList] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);

  useEffect(() => {
    // Dışarıya tıklandığında dropdown'u kapat
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    // Etkinlik dinleyicisini ekleyin
    document.addEventListener("mousedown", handleClickOutside);

    // Temizlik: bileşen kaldırıldığında etkinlik dinleyicisini kaldırın
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Dışarıya tıklandığında dropdown'u kapat
    const handleClickOutside = (event) => {
      if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
        setDropdownOpen2(false);
      }
    };

    // Etkinlik dinleyicisini ekleyin
    document.addEventListener("mousedown", handleClickOutside);

    // Temizlik: bileşen kaldırıldığında etkinlik dinleyicisini kaldırın
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const toggleDropdown2 = () => {
    setDropdownOpen2(!dropdownOpen2);
  };

  const closeNavMenu = () => { 
    setNavList(false);
  };

  return (
    <>
      <header style={{marginTop:'5px'}}>
        <div className="container flex">
          <div className="logo">
          <h3>{user}</h3>
        <div className="d-flex">
          <button
            className="btn btn-outline-primary"
            type="submit"
            onClick={logoutSubmit}
          >
            Çıxış
          </button>
        </div>
          </div>
          <div className="nav">
            <ul className={navList ? "small" : "flex"}>
            <li>
                  <Link to={'/dashboard/devices'} >
                    Cihazlar
                  </Link>
                </li>
                
                <li>
                  <Link to={'/dashboard/software-program'} >
                    Proqram təminatı
                  </Link>
                </li>
                <li>
                  <Link to={'/dashboard/kampaniyalar'} >
                    Kampaniyalar
                  </Link>
                </li>
                <li>
                  <Link to={'/dashboard/orders'} >
                    Sifarişlər
                  </Link>
                </li>
            </ul>
          </div>
          <div className="button btncall">
            <a href="tel:+994555121274">
              {" "}
              <button className="btn1">
              <IoCall size={20} />
              </button>
            </a>
          </div>
          <div className="button btnwp">
            <a href="https://api.whatsapp.com/send?phone=994555121274&text=">
              {" "}
              <button style={{backgroundColor: '#25D366'}} className="btn11">
                {/* <LuMessageSquare size={20} /> */}
                <FaWhatsapp size={20}  />
              </button>
            </a>
          </div>
        

          <div className="toggle">
            <button onClick={() => setNavList(!navList)}>
              {navList ? <i className="fa fa-times"></i> : <i className="fa fa-bars"></i>}
            </button>
          </div>
        </div>
      </header>
    </>
  );
};

export default DashboardHeader;