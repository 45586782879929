import React from "react"
import Header from "../common/header/Header"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from "../home/Home"
import Footer from "../common/footer/Footer"
import About from "../about/About"
import Sektorlar from "../sektorlar/Sektorlar"
import Avadanliqlar from "../avadanliqlar/Avadanliqlar"
import Partners from "../partners/Partners" 
import Contact from "../contact/Contact"
import Market from "../Extra/market/Market"
import Oyuncaq from "../Extra/oyuncaq/Oyuncaq" 
import Telefon from "../Extra/telefon/Telefon"
import Restoran from "../Extra/restoran/Restoran"
import Aptek from "../Extra/aptek/Aptek"
import KofeShop from "../Extra/kofeshop/KofeShop"
import Kafe from "../Extra/kafe/Kafe"
import Geyim from "../Extra/geyim/Geyim"
import Kiosk from "../Extra/kiosk/Kiosk"
import "react-toastify/dist/ReactToastify.css";
import Avto from "../Extra/avto/Avto"
import Xirdavat from "../Extra/xirdavat/Xirdavat"
import Zoo from "../Extra/zoo/Zoo"
import Parfumeriya from "../Extra/parfumeriya/Parfumeriya"
import Optik from "../Extra/optik/Optik"
import BarkodOxuyucu from "../Extra2/barkod-oxuyucu/Aptek"
import BarkodPrinter from "../Extra2/barkod-printer/Avto"
import BarkodTerezi from "../Extra2/barkod-terezi/Kafe"
import Touchscreen from "../Extra2/touchscreen/Geyim"
import PulQutulari from "../Extra2/pulqutu/Kiosk"
import Fiyatgor from "../Extra2/fiyatgor/KofeShop"
import Komputerler from "../Extra2/komputerler/Market"
import Kagizlar from "../Extra2/kagizlar/Optik"
import CardReader from "../Extra2/cardreader/Oyuncaq"
import CekPrinter from "../Extra2/touchscreen copy/CekPrinter"
import AdminPanel from '../admin/AdminPanel/AdminPanel'
import Protected from '../admin/Protected/Protected'
import CreateUser from '../admin/CreateDevices/CreateDevices'
import EditUser from '../EditUser/EditUser'
import Dashboard from '../admin/Dashboard/DashboardDevices'
import SoftwareProgram from "../SoftwareProgramMonth/SoftwareProgramMonth"
import SoftwareDashboard from "../admin/SoftwareDashboard/SoftwareDashboard"
import KampaniyalarDashboard from "../admin/KampaniyalarDashboard/KampaniyalarDashboard"
import CreateSoftware from "../admin/CreateSoftware/CreateSoftware"
import ProductDetail from "../ProductDetail/ProductDetail"
import OrdersDashboard from "../admin/OrdersDashboard/OrdersDashboard"
import { ToastContainer } from "react-toastify";
import UpdateDevice from "../admin/UpdateDevices/UpdateDevices"
import Product from "../Product"
import Cart from "../Cart/Cart"
import UpdateSoftwareProgram from "../admin/UpdateSoftwareProgram/UpdateSoftwareProgram"
import SoftwareProgramMonth from "../SoftwareProgramMonth/SoftwareProgramMonth"
import SoftwareProgramUnlimited from "../SoftwareProgramUnlimited/SoftwareProgramUnlimited"
import SoftwareProgramSingle from "../SoftwareProgramSingle/SoftwareProgramSingle"
import Kampaniyalar from "../Kampaniyalar/Kampaniyalar"
import KampaniyalarSingle from "../KampaniyalarSingle/KampaniyalarSingle"
import CreateKampaniyalar from "../admin/CreateKampaniyalar/CreateKampaniyalar"
import UpdateKampaniyalar from "../admin/UpdateKampaniyalar/UpdateKampaniyalar"

const Pages = () => {




  return (
    <>
      <Router>
        {/* ToastContainer'ı Router dışında yerleştiriyoruz */}
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover 
          theme="colored"
        /> 
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/partners' element={<Partners />} />
          <Route path='/avadanliqlar' element={<Avadanliqlar />} />
          <Route path='/sektorlar' element={<Sektorlar />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/market' element={<Market />} />
          <Route path='/oyuncaq' element={<Oyuncaq />} />
          <Route path='/telefon' element={<Telefon />} />
          <Route path='/aptek' element={<Aptek />} />
          <Route path='/kofeshop' element={<KofeShop />} />
          <Route path='/restoran' element={<Restoran />} />
          <Route path='/kafe' element={<Kafe />} />
          <Route path='/geyim' element={<Geyim />} />
          <Route path='/kiosk' element={<Kiosk />} />
          <Route path='/zoo' element={<Zoo />} />
          <Route path='/parfumeriya' element={<Parfumeriya />} />
          <Route path='/optik' element={<Optik />} />
          <Route path='/avto' element={<Avto />} />
          <Route path='/xirdavat' element={<Xirdavat />} />
          <Route path='/barkod-oxuyucu' element={<BarkodOxuyucu />} />
          <Route path='/barkod-printer' element={<BarkodPrinter />} />
          <Route path='/cek-printer' element={<CekPrinter />} />
          <Route path='/barkod-terezi' element={<BarkodTerezi />} />
          <Route path='/touchscreen' element={<Touchscreen />} />
          <Route path='/pul-qutulari' element={<PulQutulari />} />
          <Route path='/fiyatgor' element={<Fiyatgor />} />
          <Route path='/komputerler' element={<Komputerler />} />
          <Route path='/kagizlar' element={<Kagizlar />} />
          <Route path='/cardreader' element={<CardReader />} />
          <Route path="/software-program/month" element={<SoftwareProgramMonth />} />
          <Route path="/kampaniyalar" element={<Kampaniyalar />} />
          <Route path="/software-program/unlimited" element={<SoftwareProgramUnlimited />} />
          <Route path="/software-program/:id" element={<SoftwareProgramSingle />} />
          <Route path="/kampaniyalar/:id" element={<KampaniyalarSingle />} />
          <Route path='/products' element={<Product />} />
          <Route path="/cart" element={<Cart />}></Route>

          <Route path="/admin" element={<AdminPanel />} />
          <Route path="/dashboard/devices" element={<Protected Component={Dashboard} />} />
          <Route path="dashboard/devices/create"  element={<Protected Component={CreateUser} />} />
          <Route path="/dashboard/devices/update/:id" element={<Protected Component={UpdateDevice} />} />

          <Route path="/dashboard/software-program" element={<Protected Component={SoftwareDashboard} />} />
          <Route path="/dashboardSoftware/create" element={<Protected Component={CreateSoftware} />} />
          <Route path="/dashboard/softwareProgram/update/:id" element={<Protected Component={UpdateSoftwareProgram} />} />

          <Route path="/dashboard/kampaniyalar" element={<Protected Component={KampaniyalarDashboard} />} />
          <Route path="/kampaniyalar/create" element={<Protected Component={CreateKampaniyalar} />} />
          <Route path="/dashboard/kampaniyalar/update/:id" element={<Protected Component={UpdateKampaniyalar} />} />

          <Route path="/dashboard/orders" element={<Protected Component={OrdersDashboard} />} />
          <Route path="dashboard/:id/edit"  element={<Protected Component={EditUser} />} />
          <Route path="/product-detail/:id" element={<ProductDetail />} />
        </Routes>
        <Footer />
      </Router>
    </>
  )
}

export default Pages

