// import React, { useEffect, useState } from 'react';
// import Back from "../common/Back";
// import Heading from "../common/Heading";
// import img from "../images/about.jpg";
// import "./SoftwareProgram.css";
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

// const SoftwareProgram = () => {
//   const navigate = useNavigate();
//   const [list, setItems] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     getProducts();
//   }, []);

//   const getProducts = async () => {
//     try {
//       const response = await axios.get(
//         "https://www.posstore.az/posstore/softwareProgram.php/",
//         { withCredentials: false }
//       );
//       setItems(response.data);
//     } catch (error) {
//       console.error("Error fetching products:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const toDetails = (id) => {
//     navigate(`/software-program/${id}`);
//   };

//   return (
//     <section className='blog-out mb'>
//       <Back name='Proqram təminatı' title='Proqram təminatı' cover={img} />
//       <div className='container recent'>
//         {isLoading ? (
//           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }} className='container'>
//             <img style={{ width: '50px', height: '50px', margin: '0 auto' }} src='/loading.gif' alt="Loading" />
//           </div>
//         ) : (
//           <div className='content grid3 mtop'> 
//             {list.length > 0 ? (
//               list.map((val) => {
//                 const { id, image, title } = val;
//                 return (
//                   <div onClick={() => toDetails(id)} className='box shadow' key={id}>
//                     <div className='img'>
//                       <img src={`data:image/jpeg;base64,${image}`} alt='Product' />
//                     </div>
//                     <div className='text'>
//                       <h4>{title}</h4>
//                     </div>
//                   </div>
//                 );
//               })
//             ) : (
//               <div className='no-results'>Proqram təminatı tapılmadı</div>
//             )}
//           </div>
//         )}
//       </div>
//     </section>
//   );
// };

// export default SoftwareProgram;




































































import React, { useEffect, useRef, useState } from "react";
import Back from "../common/Back";
import img from "../images/about.jpg";
import "../home/recent/recent.css";
import axios from "axios";
import './aptek.css'
import { useDispatch } from "react-redux";
import { addToCart } from "../redux/orebiSlice"; 
import { FaShoppingCart } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import emailjs from '@emailjs/browser';
import Modal from "./Modal"; // Import the Modal component

const SoftwareProgramUnlimited = () => {
  const dispatch = useDispatch();
  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  
  const form = useRef();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState(""); 
  const [clientPhone, setclientPhone] = useState("");
  const [clientAddress, setclientAddress] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null); 

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        "https://www.posstore.az/posstore/softwareProgram.php",
        { withCredentials: false }
      );
      const filteredProducts = response.data.filter(item => item.type == "unlimited");
      const sortedData = filteredProducts.sort((a, b) => a.row - b.row);
      setItems(sortedData);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleProductSelect = (productId) => {
    const selectedProduct = list.find(item => item.id === productId); // Seçilen ürünü id ile bul
    setSelectedProduct(selectedProduct); // Seçilen ürünü kaydet
    console.log(selectedProduct)
    setIsModalOpen(true); // Modal'ı aç
  };
  

  const handleSubmitOrder = async () => {

    let inputs1 = {
      to_email: clientEmail,
      message: 'Ad: ' + clientName + ', Email: ' + clientEmail + ', Nömrə: ' + clientPhone + ', Ünvan: ' + clientAddress + ', Məhsul: ' + selectedProduct.title + ', Qiymət: ' + selectedProduct.price + " AZN"
    };

    let inputs11 = {
      to_email: 'namelum',
      message: 'Ad: ' + clientName + ', Email: ' + 'namelum' + ', Nömrə: ' + clientPhone + ', Ünvan: ' + clientAddress + ', Məhsul: ' + selectedProduct.title + ', Qiymət: ' + selectedProduct.price + " AZN"
    };
    
    

    if (clientEmail) {  // Eğer clientEmail boş değilse
      emailjs
        .send('service_rjrm3bj', 'template_3kb6qc8', inputs1, 'gjWu92B7j38voNfkg')
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          }
        );
    } else {
      emailjs
      .send('service_rjrm3bj', 'template_3kb6qc8', inputs11, 'gjWu92B7j38voNfkg')
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
    }




    let inputs2 = {
      name: clientName,
      email: clientEmail,
      phone: clientPhone,
      address: clientAddress,
      products: selectedProduct.title,
      totalAmt: selectedProduct.price,
    };
    



    try {
      axios.post("https://www.posstore.az/posstore/orders.php/", inputs2).then(function (response) {
        navigate("/");
        swal("Sifarişiniz tamamlandı");
        setclientName("");
        setclientEmail("");
        setclientPhone("");
        setclientAddress("");
      });
      setIsModalOpen(false);
    } catch (error) {
      console.error("Sifariş gönderilirken bir hata oluştu:", error);
    }
  };

  return (
    <>
      <section className='blog-out mb'>
        <Back name='Proqram təminatı' title='Proqram təminatı' cover={img} />
        <div className='container recent'>
          {isLoading ? (
           <div style={{display:'flex', justifyContent: 'center', alignItems:'center', height:'50vh'}} className='container'>
           <img style={{width:'50px', height:'50px', margin: '0 auto'}} src='./loading.gif'></img>
         </div>
          ) : (
            <div className='content grid4 mtop'>
              {list.length > 0 ? ( 
                list.map((val, index) => {
                  const { id, image, category, title, price } = val;
                  return (
                    <div className='box shadow' key={index}>
                      <div className='img'>
                        <img src={`https://www.posstore.az/posstore/${image}`} alt='' /> 
                      </div>
                      <div className='text'>
                        <div className='category flex'>
                          <span
                            style={{
                              background: category === "For Sale" ? "#25b5791a" : "#ff98001a",
                              color: category === "For Sale" ? "#25b579" : "#ff9800"
                            }}
                          >
                            {category}
                          </span>
                        </div>
                        <h4>{title}</h4>
                        <p style={{marginTop:'20px', fontWeight:'600', fontSize:'20px', marginBottom:'10px'}}> {price} AZN</p>
                        <a href={`/software-program/${id}`}><button className='details-button'>Ətraflı</button></a>
                        <button
  onClick={() => handleProductSelect(id)}
  style={{
    marginTop: "1rem",
    backgroundColor: "#F1592A",
    color: "white",
    cursor: "pointer",
  }}
>
  Sifariş ver
</button>
                        <button
  style={{
    marginTop: "1rem",
    backgroundColor: "#F1592A",
    color: "white",
    cursor: "pointer",
    opacity: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }}
  onClick={() => {
      dispatch(
        addToCart({
          id: id,
          name: title,
          quantity: 1,
          image: image,
          price: price
        })
      );
  }}
>
  Səbətə əlavə et
  <span style={{ marginLeft: "0.2rem" }}>
    <FaShoppingCart />
  </span>
</button>
                      </div>
                    </div>
                  );
                })
              ) : ( 
                <div className='no-results'>No results found.</div>
              )}
            </div>
          )}
        </div>
      </section>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1rem" }}>
            <h2 style={{ fontSize: "1.25rem", fontWeight: "600" }}>Sizin məlumatlarınız</h2>
            <button onClick={() => setIsModalOpen(false)} style={{ color: "white", cursor: "pointer" }}>
              &#10005;
            </button>
          </div>
          <form ref={form} onSubmit={handleSubmitOrder}>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>Ad, soyad*</label>
              <input
               name="user_name"
                type="text"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Adınızı daxil edin"
                onChange={(event) => setclientName(event.target.value)}
                value={clientName}
                required
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>E-mail</label>
              <input
              name="user_email"
                type="email"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Emailinizi daxil edin"
                onChange={(event) => setclientEmail(event.target.value)}
                value={clientEmail}
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>Əlaqə nömrəsi*</label>
              <input
              name="user_number"
                type="number"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Telefon nömrənizi daxil edin"
                onChange={(event) => setclientPhone(event.target.value)}
                value={clientPhone}
                required
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>Ünvan*</label>
              <input
              name="user_address"
                type="text"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Ünvanınızı daxil edin"
                onChange={(event) => setclientAddress(event.target.value)}
                value={clientAddress}
                required
              />
            </div>
            <div>
            <div className="flex-center" style={{ display: "flex", justifyContent: "space-between" }}>
        
       {/* {
          <div style={{ marginBottom: "1rem", color: selectedProduct.discountPrice > 0 ? "green" : "black" }}>
          {selectedProduct.discountPrice > 0 ? (
           <p>Məhsul endirimdədir! <span style={{color:'red'}}> <del>{selectedProduct.price} AZN</del> </span>{selectedProduct.discountPrice} AZN</p>
      
      
          ) : (
            <p>Məhsulun qiyməti: {selectedProduct.price} AZN</p>
          )}
        </div>
       } */}
    </div>

              <button
                type="submit"
                style={{
                  padding: "0.75rem 1.5rem",
                  backgroundColor: "#F1592A",
                  color: "white",
                  borderRadius: "10px",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                Sifariş ver
              </button>
            </div>
          </form>
      </Modal>
    </>
  );
}

export default SoftwareProgramUnlimited;

