import React, { useEffect, useRef, useState } from "react"
import Back from "../../common/Back"
import Heading from "../../common/Heading"
import RecentCard from "../../home/recent/RecentCard"
import img from "../../images/about.jpg"
import "../../home/recent/recent.css"
import RecentCard2 from "../../home/recent2/RecentCard2"
import axios from "axios"
import { FaShoppingCart } from "react-icons/fa"
import { addToCart } from "../../redux/orebiSlice"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import swal from 'sweetalert';
import emailjs from '@emailjs/browser';
import Modal from "./Modal"; // Import the Modal component

const Komputerler = () => {
  const dispatch = useDispatch();
  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const category = "Komputerlər"; // Kategori adı "Barkod Oxuyucu" olarak ayarlandı

  const form = useRef();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState(""); 
  const [clientPhone, setclientPhone] = useState("");
  const [clientAddress, setclientAddress] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null); 

  useEffect(() => {
    getProducts(); // Kategoriyi değiştirmeye gerek yok, sabit
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.posstore.az/posstore/dashboard.php?category=${encodeURIComponent(category)}`, // Kategori değeri "Barkod Oxuyucu" olarak gönderiliyor
        { withCredentials: false }
      );

      if (Array.isArray(response.data)) {
        setItems(response.data);
      } else {
        console.error("Data is not an array:", response.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleProductSelect = (productId) => {
    const selectedProduct = list.find(item => item.id === productId); // Seçilen ürünü id ile bul
    setSelectedProduct(selectedProduct); // Seçilen ürünü kaydet
    console.log(selectedProduct)
    setIsModalOpen(true); // Modal'ı aç
  };
  

  const handleSubmitOrder = async () => {

    let inputs1 = {
      to_email: clientEmail,
      message: 'Ad: ' + clientName + ', Email: ' + clientEmail + ', Nömrə: ' + clientPhone + ', Ünvan: ' + clientAddress + ', Məhsul: ' + selectedProduct.name + ', Qiymət: ' + (selectedProduct.discountPrice != 0 ? selectedProduct.discountPrice : selectedProduct.price) + " AZN"
    };

    let inputs11 = {
      to_email: 'namelum',
      message: 'Ad: ' + clientName + ', Email: ' + 'namelum' + ', Nömrə: ' + clientPhone + ', Ünvan: ' + clientAddress + ', Məhsul: ' + selectedProduct.name + ', Qiymət: ' + (selectedProduct.discountPrice != 0 ? selectedProduct.discountPrice : selectedProduct.price) + " AZN"
    };
    
    

    if (clientEmail) {  // Eğer clientEmail boş değilse
      emailjs
        .send('service_rjrm3bj', 'template_3kb6qc8', inputs1, 'gjWu92B7j38voNfkg')
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          }
        );
    } else {
      emailjs
      .send('service_rjrm3bj', 'template_3kb6qc8', inputs11, 'gjWu92B7j38voNfkg')
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
    }




    let inputs2 = {
      name: clientName,
      email: clientEmail,
      phone: clientPhone,
      address: clientAddress,
      products: selectedProduct.name,
      totalAmt: selectedProduct.discountPrice != 0 ? selectedProduct.discountPrice : selectedProduct.price,
    };
    



    try {
      axios.post("https://www.posstore.az/posstore/orders.php/", inputs2).then(function (response) {
        navigate("/");
        swal("Sifarişiniz tamamlandı");
        setclientName("");
        setclientEmail("");
        setclientPhone("");
        setclientAddress("");
      });
      setIsModalOpen(false);
    } catch (error) {
      console.error("Sifariş gönderilirken bir hata oluştu:", error);
    }
  };
  return (
    <>
       <section className='blog-out mb'>
        <Back name='Komputerlər' title='Avadanlıqlar-Komputerlər' cover={img} />
        <div className='container recent'>
        {isLoading ? (
           <div style={{display:'flex', justifyContent: 'center', alignItems:'center', height:'50vh'}} className='container'>
           <img style={{width:'50px', height:'50px', margin: '0 auto'}} src='./loading.gif'></img>
         </div>
          ) : (
        <div className='content grid3 mtop'>
        {list.map((val, index) => {
          const {id, image,category, name, price, discountPrice, count } = val
          return (
            <div className='box shadow' key={index}>
              <div className='img'>
                <img src={`data:image/jpeg;base64,${image}`} alt='' />
              </div>
              <div className='text'>
                <div className='category flex'>
                  <span style={{ background: category === "For Sale" ? "#25b5791a" : "#ff98001a", color: category === "For Sale" ? "#25b579" : "#ff9800" }}>{category}</span>
                  <i className='fa fa-heart'></i>
                </div>
                <h4>{name}</h4>
                <p style={{marginBottom:"10px"}}>
                          <i className='fa fa-location-dot'></i> Bakı, Azərbaycan
                        </p>
                        <div style={{display:'flex'}}>
                       <a href={`/product-detail/${id}`}><button className='details-button'>Ətraflı</button></a>
                        <button
  onClick={() => handleProductSelect(id)}
  style={{
    marginLeft: "1rem",
    backgroundColor: "#F1592A",
    color: "white",
    cursor: count == 0 ? "not-allowed" : "pointer",
    opacity: count == 0 ? 0.5 : 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }}
  disabled={count == 0}  // Eğer count 0 ise butonu devre dışı bırak
>
  Sifariş ver
</button>
</div>
                        <button
  style={{
    marginTop: "1rem",
    backgroundColor: "#F1592A",
    color: "white",
    cursor: count == 0 ? "not-allowed" : "pointer",
    opacity: count == 0 ? 0.5 : 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }}
  onClick={() => {
    if (count > 0) {
      dispatch(
        addToCart({
          id: id,
          name: name,
          quantity: 1,
          image: image,
          price: price,
          discountPrice: discountPrice,
        })
      );
    }
  }}
  disabled={count == 0} // Disable the button if count is 0
>
  Səbətə əlavə et
  <span style={{ marginLeft: "0.2rem" }}>
    <FaShoppingCart />
  </span>
</button>
{discountPrice > 0 ? <p style={{marginTop:'10px', fontWeight:'600', color:"green", fontSize:'20px'}}><span style={{color:'red'}}><del>{price} AZN</del></span> {discountPrice} AZN</p> :
                        <p style={{marginTop:'10px', fontWeight:'600', fontSize:'20px'}}> {price} AZN</p>}
              </div>
            </div>
          )
        })}
      </div>
       )}
        </div>
      </section>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1rem" }}>
            <h2 style={{ fontSize: "1.25rem", fontWeight: "600" }}>Sizin məlumatlarınız</h2>
            <button onClick={() => setIsModalOpen(false)} style={{ color: "white", cursor: "pointer" }}>
              &#10005;
            </button>
          </div>
          <form ref={form} onSubmit={handleSubmitOrder}>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>Ad, soyad*</label>
              <input
               name="user_name"
                type="text"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Adınızı daxil edin"
                onChange={(event) => setclientName(event.target.value)}
                value={clientName}
                required
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>E-mail</label>
              <input
              name="user_email"
                type="email"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Emailinizi daxil edin"
                onChange={(event) => setclientEmail(event.target.value)}
                value={clientEmail}
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>Əlaqə nömrəsi*</label>
              <input
              name="user_number"
                type="number"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Telefon nömrənizi daxil edin"
                onChange={(event) => setclientPhone(event.target.value)}
                value={clientPhone}
                required
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>Ünvan*</label>
              <input
              name="user_address"
                type="text"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Ünvanınızı daxil edin"
                onChange={(event) => setclientAddress(event.target.value)}
                value={clientAddress}
                required
              />
            </div>
            <div>
            <div className="flex-center" style={{ display: "flex", justifyContent: "space-between" }}>
        
       {/* {
          <div style={{ marginBottom: "1rem", color: selectedProduct.discountPrice > 0 ? "green" : "black" }}>
          {selectedProduct.discountPrice > 0 ? (
           <p>Məhsul endirimdədir! <span style={{color:'red'}}> <del>{selectedProduct.price} AZN</del> </span>{selectedProduct.discountPrice} AZN</p>
      
      
          ) : (
            <p>Məhsulun qiyməti: {selectedProduct.price} AZN</p>
          )}
        </div>
       } */}
    </div>

              <button
                type="submit"
                style={{
                  padding: "0.75rem 1.5rem",
                  backgroundColor: "#F1592A",
                  color: "white",
                  borderRadius: "10px",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                Sifariş ver
              </button>
            </div>
          </form>
      </Modal>
    </>
  )
}

export default Komputerler
