import axios from "axios";
import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import DashboardHeader from "../DashboardHeader/DashboardHeader";

export default function Dashboard() {

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state


  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    setLoading(true); // Set loading to true before fetching data
    axios
      .get("https://www.posstore.az/posstore/softwareProgram.php/", {
        withCredentials: false
      })
      .then(function (response) {
        console.log(response.data)
        setProducts(response.data);
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch(function (error) {
        console.error("Error fetching products:", error);
        setLoading(false); // Set loading to false even if there's an error
      });
  }

  const deleteUser = (id) => {
    axios
      .delete(`https://www.posstore.az/posstore/softwareProgram.php/${id}/delete`, {
        withCredentials: false
      })
      .then(function (response) {
        getProducts();
      });
  };

  const updateUserLink = (id) => {
    return `/dashboard/softwareProgram/update/${id}`; // Güncelleme sayfasına yönlendiren URL
  };
 
  return (
    <>
    <DashboardHeader/>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container"></div>
      </nav>
      <div className="container" style={{ paddingTop: 50 }}>
        <li>
          <Link style={{ color: 'black' }} to="/dashboardSoftware/create">Proqram təminatı əlavə et</Link>
        </li>
        <div>
          <h1>Proqram təminatları</h1>
          {loading ? ( // Conditionally render based on loading state
            <img style={{width:'50px', height:'50px'}} src="/./loading.gif"></img>
          )  : (
            <table>
              <thead>
                <tr>
                  <th>Actions</th>
                  <th>ID</th>
                  <th>Başlıq</th>
                  <th>Qiymət</th>
                  <th>Yazı</th>
                  <th>Qeyd</th>
                  <th>Növ</th>
                  <th>Sıra</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product, key) => (
                  <tr key={key}>
                    <td style={{display:'flex'}}>
                      <button style={{marginRight:'5px'}} onClick={() => deleteUser(product.id)}>Sil</button>
                      <Link to={updateUserLink(product.id)}>
                      <button>Yenilə</button>
                    </Link>
                    </td>
                    <td>{product.id}</td>
                    <td>{product.title}</td>
                    <td>{product.price}</td>
                    <td>{product.text}</td>
                    <td>{product.extra}</td>
                    <td>{product.type}</td>
                    <td>{product.row}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}

